import axios from 'axios'
import { $busEmit } from '@/plugins/bus'
import { useAuthStore } from '@/stores'

// Get token
const storage = window.localStorage
const token = storage.getItem('telecom_token')

// Set configs
const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

// Request
client.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()
    config.headers.Authorization = token || authStore.tokenGetter
    return config
  },
  (error) => Promise.reject(error)
)

// Response
client.interceptors.response.use(
  (response) => {
    const data = response.data
    return data.errors ? Promise.reject(data) : response
  },
  (error) => {
    const authStore = useAuthStore()

    if (authStore.isOfflineGetter) return Promise.reject(error)
    if (axios.isCancel(error)) return Promise.reject(error)

    if (error?.response?.status === 403) {
      $busEmit('setToast', {
        color: 'red',
        icon: 'alert-triangle',
        iconPack: 'solid',
        title: 'Ошибка доступа',
        message: 'У вас не достаточно прав, обратитесь к Администратору.'
      })
    } else if (error?.response?.status === 400) {
      console.log(error?.response?.data?.errors)
    } else if (error?.response?.status === 401) {
      storage.removeItem('telecom_token')
      window.location.replace('/auth/signin')
    } else if (error?.response?.status === 413 || error.code === 'ERR_NETWORK') {
      $busEmit('setToast', {
        color: 'red',
        icon: 'alert-triangle',
        iconPack: 'solid',
        title: 'Ошибка 413',
        message: 'Общий размер файлов слишком большой, попробуйте загрузить другой файл'
      })
    } else {
      $busEmit('setToast', {
        color: 'red',
        icon: 'alert-triangle',
        iconPack: 'solid',
        title: error?.response
          ? error?.response?.status + ' ' + error?.response?.data?.message
          : error?.message || 'Не известная ошибка',
        message: 'Что-то пошло не так, обновите страницу или обратитесь к Администратору.'
      })
    }

    return Promise.reject(error)
  }
)

export default client
