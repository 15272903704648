// import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { $busEmit } from '@/plugins/bus'
import {
  localUpdateApplication,
  localUpdateFiles,
  localRemoveFile,
  localRemoveFileSingle,
  localGetAll
} from '@/plugins/localforage'
import { authApi, notificationsApi, applicationsApi, uploaderApi } from '@/api'

// Auth
export const useAuthStore = defineStore('auth', () => {
  const token = ref(localStorage.getItem('telecom_token') || '')
  const profile = ref(null)
  const isOffline = ref(false)

  // Getters
  const tokenGetter = computed(() => token.value)
  const profileGetter = computed(() => profile.value)
  const isOfflineGetter = computed(() => isOffline.value)

  // Set token
  async function SET_TOKEN(payload) {
    token.value = payload
    localStorage.setItem('telecom_token', payload)
  }

  // Get profile
  async function GET_PROFILE() {
    let result = null
    try {
      const params = {
        includes: ['position']
      }
      const { data } = await authApi.getProfile({ params })
      profile.value = data.user
    } catch (error) {
      result = error.response.data.errors
    }
    return result
  }

  // Logout
  function LOGOUT() {
    return new Promise((resolve) => {
      token.value = ''
      localStorage.removeItem('telecom_token')
      localStorage.removeItem('applicationFilter')
      resolve()
    })
  }

  // Forgot password
  async function FORGOT_PASSWORD(payload) {
    return await authApi.forgotPassword(payload)
  }

  // Set offline state
  function SET_OFFLINE_STATE(payload) {
    isOffline.value = payload
  }

  return {
    isOfflineGetter,
    tokenGetter,
    profileGetter,
    SET_TOKEN,
    GET_PROFILE,
    LOGOUT,
    FORGOT_PASSWORD,
    SET_OFFLINE_STATE
  }
})

// Notifications
export const useNotificationsStore = defineStore('notifications', () => {
  // State
  const filters = ref(
    localStorage.getItem('notifyFilter')
      ? JSON.parse(localStorage.getItem('notifyFilter'))
      : {
          type: [
            'application_created',
            'application_updated',
            'application_changed_status_in_work',
            'application_changed_status_completed'
          ],
          order: ['createdAt', 'DESC']
        }
  )
  const unreadNotifications = ref([])
  const readNotifications = ref([])
  const unreadNotificationCount = ref(0)

  // Getters
  const filtersGetter = computed(() => filters.value)
  const unreadNotificationsGetter = computed(() => unreadNotifications.value)
  const readNotificationsGetter = computed(() => readNotifications.value)
  const unreadNotificationCountGetter = computed(() => unreadNotificationCount.value)

  // Filter
  async function SET_FILTER(params) {
    filters.value = params
    localStorage.setItem('notifyFilter', JSON.stringify(params))
  }
  async function RESET_FILTER() {
    filters.value = {
      type: [
        'application_created',
        'application_updated',
        'application_changed_status_in_work',
        'application_changed_status_completed'
      ],
      order: ['createdAt', 'DESC']
    }
    localStorage.removeItem('notifyFilter')
  }

  // Notifications
  async function GET_ITEMS() {
    // Get list
    const unreadNotificationsResponse = await notificationsApi.get({
      page: 1,
      pageSize: 99999,
      includes: ['initiator'],
      ...filters.value
    })
    unreadNotifications.value = unreadNotificationsResponse.data.notifications.rows

    // Get count
    const unreadNotificationCountResponse = await notificationsApi.getCount({
      viewed: 0,
      type: [
        'application_created',
        'application_updated',
        'application_changed_status_in_work',
        'application_changed_status_completed'
      ]
    })
    unreadNotificationCount.value = unreadNotificationCountResponse.data.count || 0
  }

  // Set view all
  async function MARK_AS_READ(notificationIds, viewed) {
    await notificationsApi.viewSwitchMultiple({ notificationIds, viewed })
    await GET_ITEMS()
  }

  return {
    filtersGetter,
    unreadNotificationsGetter,
    readNotificationsGetter,
    unreadNotificationCountGetter,

    SET_FILTER,
    RESET_FILTER,
    GET_ITEMS,
    MARK_AS_READ
  }
})

// Applications
export const useApplicationsStore = defineStore('applications', () => {
  // Imports
  const authStore = useAuthStore()

  // State
  const filters = ref(
    localStorage.getItem('applicationFilter')
      ? JSON.parse(localStorage.getItem('applicationFilter'))
      : {
          status: ['in_work', 'new'],
          order: ['createdAt', 'DESC'],
          onlyExecutor: false
        }
  )
  const items = ref([])
  const item = ref(null)
  const loadingMedia = ref({})
  const lastViewedId = ref(localStorage.getItem(`last-viewed`) || undefined)

  // Getters
  const filtersGetter = computed(() => filters.value)
  const itemsGetter = computed(() => items.value)
  const itemGetter = computed(() => item.value)
  const loadingMediaGetter = computed(() => loadingMedia.value)
  const lastViewedIdGetter = computed(() => lastViewedId.value)

  // Фильтры
  async function SET_FILTER(params) {
    filters.value = params
    localStorage.setItem('applicationFilter', JSON.stringify(params))
  }
  async function RESET_FILTER() {
    filters.value = {
      status: ['in_work', 'new'],
      order: ['createdAt', 'DESC'],
      onlyExecutor: false
    }
    localStorage.removeItem('applicationFilter')
  }

  // Последний просмотреный
  function SET_LAST_VIEWED(blockId) {
    lastViewedId.value = blockId
    localStorage.setItem(`last-viewed`, blockId)
  }

  // Сохранение одной заявки
  async function SAVE_ITEM(applicationId) {
    const params = { includes: ['order.region', 'order.city', 'executor', 'order.files'] }
    await GET_ITEM(applicationId, params)
    await UPDATE_ITEM()

    // Обновляем список
    const paramsItems = {
      pageSize: 99999,
      includes: ['order.region', 'order.city', 'order.responsible', 'executor']
    }
    if (authStore.profileGetter?.position?.role !== 'admin') {
      paramsItems.executorId = authStore.profileGetter.id
    }
    GET_ITEMS({ params: paramsItems })

    let timerID = -1
    const app = itemsGetter.value.find((el) => el.id === Number(applicationId))
    if (app?.needSave) {
      timerID = setTimeout(() => {
        SAVE_ITEM(applicationId)
      }, 100)
    } else {
      clearTimeout(timerID)
    }
    // setNeedSave(applicationId, false)
  }

  // Получаем список заявок и попутно проверяем надо ли сохранить
  async function GET_ITEMS({ params }) {
    const filtr = { ...filters.value }
    delete filtr.onlyExecutor
    const { data } = await applicationsApi.getItems({ params: { ...params, ...filtr } })
    const arr = data?.applications?.rows.filter((el) => !!el.order)
    const res = []

    for (let index = 0; index < arr.length; index++) {
      const element = arr[index]

      // Получаем дату последнего сохранения локально
      const { files, application } = await localGetAll(element.id)
      const needSave = await checkNeedSave(element, application?.obj, files)

      res.push({
        ...element,
        savedDate: application?.date,
        needSave
      })
    }

    items.value = res
  }
  async function checkNeedSave(backendData, localData, files) {
    const arr = files ? Object.keys(files) : []
    return (
      (localData &&
        JSON.stringify(backendData?.content?.categories) !== JSON.stringify(localData?.content?.categories)) ||
      !!arr?.length
    )
  }
  async function setNeedSave(applicationId, state = true) {
    for (let index = 0; index < items.value.length; index++) {
      const element = items.value[index]
      if (element.id === Number(applicationId)) {
        element.needSave = state
        break
      }
    }
  }

  // Получаем заявку и сливаем ее с локальной заявкой
  async function GET_ITEM(applicationId, params) {
    // Получаем заявку с localForage
    const { files, application } = await localGetAll(applicationId)
    const localData = application?.obj

    // Закидываем в стор не закаченые файлы
    for (const key in files) {
      if (Object.hasOwnProperty.call(files, key)) {
        const file = files[key]
        loadingMedia.value[key] = {
          file,
          controller: null
        }
      }
    }

    // Получаем заявку с бека
    const { data } = await applicationsApi.getItem(applicationId, params)
    const backendData = data?.application || null

    // Проверяем если есть оба и они разные то делаем слиляние
    if (
      localData &&
      backendData &&
      JSON.stringify(localData.content.categories) !== JSON.stringify(backendData.content.categories)
    ) {
      // Делаем слияние всех текстов со статусом (local)
      if (localData?.content?.categories?.length) {
        for (let i = 0; i < localData.content.categories.length; i++) {
          const localCategory = localData.content.categories[i]
          const backendCategory = backendData.content.categories[i]

          if (JSON.stringify(backendCategory.content.rows) !== JSON.stringify(localCategory.content.rows)) {
            for (let index = 0; index < localCategory.content.rows.length; index++) {
              const backendRow = backendCategory.content.rows[index]
              const localRow = localCategory.content.rows[index]

              if (localCategory.content.rows?.length > backendCategory.content.rows?.length && !backendRow) {
                // Если в лольном хранилище есть новые блоки
                backendCategory.content.rows.push(localRow)
              } else if (localCategory.content.rows?.length < backendCategory.content.rows?.length) {
                // Если в беке есть старые блоки
                const localIds = localCategory.content.rows.map((el) => el.id)
                const backIds = backendCategory.content.rows.map((el) => el.id)
                const intersection = backIds.filter((id) => !localIds.includes(id))

                for (let indx = 0; indx < intersection.length; indx++) {
                  const id = intersection[indx]
                  const findIndex = backendCategory.content.rows.findIndex((el) => el.id === id)
                  if (findIndex !== -1) backendCategory.content.rows.splice(findIndex, 1)
                }
              } else {
                // Бежим по строкам каждой категории и сравниваем
                mergeRecursive(backendCategory.content.rows, localCategory.content.rows)
              }
            }
          }
        }
      }
    }

    // Записываем слитые данные
    item.value = backendData ? backendData : localData
    return item.value
  }
  function mergeRecursive(backendRows, localRows) {
    console.log('mergeRecursive', backendRows, localRows)
    for (let index = 0; index < localRows?.length; index++) {
      const backendRow = backendRows[index]
      const localRow = localRows[index]

      // Проверяем в корне есть ли что-то новое
      // if (JSON.stringify(localRow) !== JSON.stringify(backendRow)) {
      if (!deepEqual(localRow, backendRow)) {
        if (!backendRow && localRow) {
          backendRows.push(localRow)
        } else {
          if (backendRow?.type === 'subcategory') {
            if (localRow?.rows?.length > backendRow?.rows?.length && !backendRow) {
              // Если в лольном хранилище есть новые блоки
              backendRow?.rows.push(localRows)
            } else if (localRow?.rows?.length < backendRow?.rows?.length) {
              // Если в беке есть старые блоки
              // const localIds = localRow.rows.map((el) => el.id)
              const localIds = new Set(localRow?.rows.map((el) => el.id))
              // const backIds = backendRow?.rows.map((el) => el.id)
              const backIds = new Set(backendRow?.rows.map((el) => el.id))
              // const intersection = backIds.filter((id) => !localIds.includes(id))
              const intersection = []
              for (let id of backIds) {
                if (!localIds.has(id)) {
                  intersection.push(id)
                }
              }

              for (let indx = 0; indx < intersection.length; indx++) {
                const id = intersection[indx]
                const findIndex = backendRow?.rows.findIndex((el) => el.id === id)
                if (findIndex !== -1) backendRow?.rows.splice(findIndex, 1)
              }
            } else {
              mergeRecursive(backendRow.rows, localRow.rows)
            }
          }

          if (backendRow.type === 'boolean') {
            // Сливаем массивы
            if (localRow?.conditions?.yes?.length > backendRows?.conditions?.yes?.length && !backendRow) {
              // Если в лольном хранилище есть новые блоки
              backendRows?.conditions?.yes.push(localRow?.conditions?.yes)
            } else if (localRow?.conditions?.yes?.length < backendRow?.conditions?.yes?.length) {
              // Если в беке есть старые блоки
              // const localIds = localRow?.conditions?.yes.map((el) => el.id)
              const localIds = new Set(localRow?.conditions?.yes.map((el) => el.id))
              // const backIds = backendRow?.conditions?.yes.map((el) => el.id)
              const backIds = new Set(backendRow?.conditions?.yes.map((el) => el.id))
              // const intersection = backIds.filter((id) => !localIds.includes(id))
              const intersection = []
              for (let id of backIds) {
                if (!localIds.has(id)) {
                  intersection.push(id)
                }
              }
              // console.log(localIds, backIds, intersection)
              for (let indx = 0; indx < intersection.length; indx++) {
                const id = intersection[indx]
                const findIndex = backendRow?.conditions?.yes.findIndex((el) => el.id === id)
                if (findIndex !== -1) backendRow?.conditions?.yes.splice(findIndex, 1)
              }
            }
            if (localRow?.conditions?.no?.length > backendRows?.conditions?.no?.length && !backendRow) {
              // Если в лольном хранилище есть новые блоки
              backendRows?.conditions?.no.push(localRow?.conditions?.no)
            } else if (localRow?.conditions?.no?.length < backendRow?.conditions?.no?.length) {
              // Если в беке есть старые блоки
              // const localIds = localRow?.conditions?.no.map((el) => el.id)
              const localIds = new Set(localRow?.conditions?.no.map((el) => el.id))
              // const backIds = backendRow?.conditions?.no.map((el) => el.id)
              const backIds = new Set(backendRow?.conditions?.no.map((el) => el.id))
              // const intersection = backIds.filter((id) => !localIds.includes(id))
              const intersection = []
              for (let id of backIds) {
                if (!localIds.has(id)) {
                  intersection.push(id)
                }
              }
              // console.log(localIds, backIds, intersection)
              for (let indx = 0; indx < intersection.length; indx++) {
                const id = intersection[indx]
                const findIndex = backendRow?.conditions?.no.findIndex((el) => el.id === id)
                if (findIndex !== -1) backendRow?.conditions?.no.splice(findIndex, 1)
              }
            }

            // Сливаем корень
            if (backendRow.comment !== localRow.comment || backendRow.value !== localRow.value) {
              if (localRow.status !== 'local') backendRows[index].status = localRow.status
              backendRows[index].comment = localRow.comment
              backendRows[index].value = localRow.value
            }
            // if (JSON.stringify(backendRow.conditions.yes) !== JSON.stringify(localRow.conditions.yes)) {
            if (!deepEqual(backendRow.conditions.yes, localRow.conditions.yes)) {
              mergeRecursive(backendRow.conditions.yes, localRow.conditions.yes)
            }
            // if (JSON.stringify(backendRow.conditions.no) !== JSON.stringify(localRow.conditions.no)) {
            if (!deepEqual(backendRow.conditions.no, localRow.conditions.no)) {
              mergeRecursive(backendRow.conditions.no, localRow.conditions.no)
            }
          }

          if (backendRow.type === 'text' && backendRow.value !== localRow.value) {
            if (localRow.status !== 'local') backendRows[index].status = localRow.status
            backendRows[index].value = localRow.value
          }

          if (backendRow.type === 'media' && !backendRow.comment) {
            backendRows[index].comment = localRow.comment
          }

          if (backendRow.type === 'multi-media' && !backendRow.comment) {
            backendRows[index].comment = localRow.comment

            if (localRow?.uploaded?.length < backendRow?.uploaded?.length) {
              // Если в беке есть старые блоки
              // const localIds = localRow?.uploaded.map((el) => el.id)
              const localIds = new Set(localRow?.uploaded.map((el) => el.id))
              // const backIds = backendRow?.uploaded.map((el) => el.id)
              const backIds = new Set(backendRow?.uploaded.map((el) => el.id))
              // const intersection = backIds.filter((id) => !localIds.includes(id))
              const intersection = []
              for (let id of backIds) {
                if (!localIds.has(id)) {
                  intersection.push(id)
                }
              }
              for (let indx = 0; indx < intersection.length; indx++) {
                const id = intersection[indx]
                const findIndex = backendRow?.uploaded.findIndex((el) => el.id === id)
                if (findIndex !== -1) backendRow?.uploaded.splice(findIndex, 1)
              }
            }
          }
        }
      }
    }
  }
  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false
    }

    return true
  }

  // Отправляем заявку на бек и попутно меняем статусы local на sended
  async function UPDATE_ITEM() {
    if (authStore.isOfflineGetter || !item.value) {
      setNeedSave(itemGetter.value.id)
      return Promise.resolve(true)
    }

    // Меняем статусы и отправляем на бек
    const cloneItem = cloneDeep(itemGetter.value)
    for (let i = 0; i < cloneItem.content.categories.length; i++) {
      const category = cloneItem.content.categories[i]
      await recursiveUpdateData(category.content.rows, itemGetter.value.id)
    }
    await localUpdateApplication(cloneItem.id, cloneItem)
    return applicationsApi.updateItem(cloneItem.id, { content: cloneItem.content })
  }
  async function recursiveUpdateData(rows, applicationId) {
    for (const row of rows) {
      if (row.type === 'subcategory') {
        await recursiveUpdateData(row.rows, applicationId)
      }

      if (row.type === 'boolean') {
        if (row.status === 'local' && row.value) row.status = 'sended'
        if (row.value && (row?.conditions?.yes?.length || row?.conditions?.no?.length))
          await recursiveUpdateData(row.conditions[row.value], applicationId)
      }

      if (row.type === 'text') {
        if (row.status === 'local' && row.value) row.status = 'sended'
      }

      if (row.type === 'media') {
        const { files } = await localGetAll(applicationId)
        if (files && files[row.id]) await uploadFiles(applicationId, row, [], files[row.id])
      }

      if (row.type === 'multi-media') {
        const { files } = await localGetAll(applicationId)
        if (files && files[row.id]?.length) await uploadFiles(applicationId, row, files[row.id], null)
      }
    }
  }
  // async function uploadFiles(applicationId, row, files = [], file = null) {
  //   try {
  //     const params = {}
  //     const payload = new FormData()
  //     if (file) {
  //       payload.append(`files`, file)
  //       if (!file?.type.includes('video')) {
  //         params.cropOptions = '[{"name":"small","width":350,"height":180}]'
  //         row.uploaded.type = 'photo'
  //       } else {
  //         row.uploaded.type = 'video'
  //       }
  //     } else {
  //       for (let i = 0; i < files.length; i++) {
  //         const el = files[i]
  //         payload.append(`files`, el.file)
  //         payload.append(`names[]`, el.id)
  //         payload.append(`comments[]`, el.type)
  //         if (el.type === 'photo') {
  //           params.cropOptions = '[{"name":"small","width":350,"height":180}]'
  //         }
  //       }
  //     }

  //     const controller = new AbortController()
  //     SET_LOADING_MEDIA_CONTROLLER(row.id, controller)
  //     const { data } = await uploaderApi.upload(payload, params, controller.signal)
  //     const res = data.files
  //     console.log('res_uploadFiles', res)

  //     // Записываем полученые данные
  //     if (file) {
  //       console.log('row.comment', row.comment)
  //       row.status = 'sended'
  //       // row.comment = loadingMediaGetter.value[row.id]?.comment
  //       row.uploaded.url = res[0].src
  //       row.uploaded.urlThumb = row.uploaded.type === 'video' ? res[0].extra.thumbnail : res[0]?.extra?.thumbs?.small
  //       delete row.uploaded.file
  //       delete row.uploaded.comment
  //       delete row.uploaded.alert
  //     } else {
  //       // Если множество
  //       for (let i = 0; i < res.length; i++) {
  //         const resItem = res[i]
  //         const index = row.uploaded.findIndex((el) => el.id === resItem.name)
  //         const urlThumb = resItem.comment === 'video' ? resItem.extra.thumbnail : resItem?.extra?.thumbs?.small
  //         if (index !== -1) {
  //           row.uploaded.splice(index, 1, {
  //             id: resItem.name,
  //             type: resItem.comment,
  //             status: 'sended',
  //             url: resItem.src,
  //             urlThumb
  //           })
  //           // row.uploaded[index].id = resItem.name
  //           // row.uploaded[index].type = resItem.comment
  //           // row.uploaded[index].status = 'sended'
  //           // row.uploaded[index].url = resItem.src
  //           // row.uploaded[index].urlThumb = urlThumb
  //         } else {
  //           row.uploaded.push({
  //             id: uuidv4(),
  //             type: resItem.comment,
  //             status: 'sended',
  //             url: resItem.src,
  //             urlThumb
  //           })
  //         }
  //       }
  //       row.status = 'sended'
  //     }

  //     // Удаляем загруженый файл из ИндексДБ и стора
  //     await REMOVE_LOADING_MEDIA(applicationId, row.id)

  //     $busEmit('setToast', {
  //       color: 'green',
  //       icon: 'check-circle',
  //       iconPack: 'solid',
  //       title: 'Файл успешно загружен',
  //       message: `Файл ${row?.example?.name || 'Не известно'} успешно загружен на сервер`
  //     })
  //   } catch (error) {
  //     row.status = 'local'
  //     if (axios.isCancel(error)) console.log('Upload canceled: ', error.message)
  //     else console.log('Upload: ', error)
  //     SET_LOADING_MEDIA_CONTROLLER(row.id, null)
  //   }
  // }
  /**
   * Uploads files to the server and updates the row's uploaded files array with the results.
   * @async
   * @function uploadFiles
   * @param {string} applicationId - The ID of the application.
   * @param {Object} row - The row object to update with the uploaded files.
   * @param {Array} [files=[]] - An array of files to upload.
   * @param {Object} [file=null] - A single file to upload.
   * @returns {Promise<void>}
   */
  async function uploadFiles(applicationId, row, files = [], file = null) {
    try {
      const uploadPromises = []

      if (file) {
        uploadPromises.push(uploadSingleFile(file, row, row.id, 'photo'))
      } else {
        files.forEach((el) => {
          uploadPromises.push(uploadSingleFile(el.file, row, el.id, el.type))
        })
      }

      const allResults = await Promise.allSettled(uploadPromises)
      const successfulUploads = allResults
        .filter((result) => result.status === 'fulfilled')
        .map((result) => result.value)

      successfulUploads.forEach((resItem) => {
        const urlThumb = resItem.comment === 'video' ? resItem.extra.thumbnail : resItem?.extra?.thumbs?.small

        if (Array.isArray(row.uploaded)) {
          const index = row.uploaded.findIndex((el) => el.id === resItem.name)

          if (index !== -1) {
            row.uploaded.splice(index, 1, {
              id: resItem.name,
              type: resItem.comment,
              status: 'sended',
              url: resItem.src,
              urlThumb
            })
          } else {
            row.uploaded.push({
              id: uuidv4(),
              type: resItem.comment,
              status: 'sended',
              url: resItem.src,
              urlThumb
            })
          }
        } else {
          row.uploaded = {
            id: resItem.name,
            type: resItem.comment,
            status: 'sended',
            url: resItem.src,
            urlThumb
          }
        }
      })

      await REMOVE_LOADING_MEDIA(applicationId, row.id)
      row.status = 'sended'

      $busEmit('setToast', {
        color: 'green',
        icon: 'check-circle',
        iconPack: 'solid',
        title: 'Файл успешно загружен',
        message: `Файл ${row?.example?.name || 'Не известно'} успешно загружен на сервер`
      })
    } catch (error) {
      row.status = 'local'
      console.log('Upload: ', error)
      SET_LOADING_MEDIA_CONTROLLER(row.id, null)
    }
  }

  /**
   * Uploads a single file to the server.
   * @async
   * @function
   * @param {File} file - The file to upload.
   * @param {Object} row - The row object.
   * @param {string|null} [id=null] - The ID of the file.
   * @param {string|null} [type=null] - The type of the file.
   * @returns {Promise<Object>} - The uploaded file object.
   */
  async function uploadSingleFile(file, row, id = null, type = null) {
    const params = {}
    const payload = new FormData()

    payload.append(`files`, file)

    if (id) {
      payload.append(`names[]`, id)
      payload.append(`comments[]`, type)
    }

    if (type === 'photo' || !file?.type.includes('video')) {
      params.cropOptions = '[{"name":"small","width":350,"height":180}]'
    }

    const controller = new AbortController()
    SET_LOADING_MEDIA_CONTROLLER(row.id, controller)
    const { data } = await uploaderApi.upload(payload, params, controller.signal)

    return data.files[0]
  }

  // Обновляем в сторе элемент блока
  async function UPDATE_ITEM_OBJ(applicationId, categoryId, blockId, obj) {
    if (item.value.id === Number(applicationId) && item.value?.content?.categories) {
      for (let i = 0; i < item.value.content.categories.length; i++) {
        const cat = item.value.content.categories[i]
        if (cat.id === Number(categoryId) && cat?.content?.rows?.length) {
          recursiveFindImage(cat.content.rows, blockId, obj)
        }
      }
    }
  }
  function recursiveFindImage(arr, blockId, obj) {
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index]
      if (item.id === blockId) {
        item.comment = obj.comment
        item.status = obj.status
      } else if (item.type === 'subcategory' && item.rows?.length) {
        recursiveFindImage(item.rows, blockId, obj)
      } else if (item.type === 'boolean') {
        if (item?.conditions?.yes?.length) recursiveFindImage(item.conditions.yes, blockId, obj)
        if (item?.conditions?.no?.length) recursiveFindImage(item.conditions.no, blockId, obj)
      }
    }
  }

  // Записываем файлы что надо сохранить
  function SET_LOADING_MEDIA_CONTROLLER(blockId, controller) {
    loadingMedia.value[blockId].controller = controller
  }
  async function SET_LOADING_MEDIA(applicationId, blockId, payload) {
    loadingMedia.value[blockId] = payload
    await localUpdateFiles(applicationId, blockId, payload.file)
  }
  async function REMOVE_LOADING_MEDIA(applicationId, blockId) {
    delete loadingMedia.value[blockId]
    await localRemoveFile(applicationId, blockId)
  }
  async function REMOVE_LOADING_MEDIA_SINGLE(applicationId, blockId, fileId) {
    const index = loadingMedia.value[blockId].file.findIndex((el) => el.id === fileId)
    if (index !== -1) loadingMedia.value[blockId].file.splice(index, 1)
    await localRemoveFileSingle(applicationId, blockId, fileId)
  }

  // Обновляем дату
  function UPDATE_ITEM_SAVED_DATE(applicationId, date) {
    for (let index = 0; index < items.value.length; index++) {
      const element = items.value[index]
      if (element.id === Number(applicationId)) {
        element.savedDate = date
        break
      }
    }
  }

  return {
    filtersGetter,
    item,
    itemsGetter,
    itemGetter,
    loadingMediaGetter,
    lastViewedIdGetter,
    SAVE_ITEM,
    UPDATE_ITEM_SAVED_DATE,

    // Нужные функции
    SET_FILTER,
    RESET_FILTER,
    SET_LAST_VIEWED,

    GET_ITEMS,
    GET_ITEM,
    UPDATE_ITEM,
    UPDATE_ITEM_OBJ,

    SET_LOADING_MEDIA,
    REMOVE_LOADING_MEDIA,
    REMOVE_LOADING_MEDIA_SINGLE
  }
})
