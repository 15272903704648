// Styles
import '@aistim/vue3-ui/styles'
// import './assets/scss/main.scss'
// import '@fancyapps/ui/dist/fancybox/fancybox.css'

// Npm
// import vClickOutside from 'click-outside-vue3'
// import { Fancybox } from '@fancyapps/ui'
import { createPinia } from 'pinia'
// import Vue3TouchEvents from 'vue3-touch-events'
import { addIconPacks } from '@aistim/vue3-ui/plugins'
import { createApp } from 'vue'

// Base
import App from './App.vue'
// import router from './router'

// Icons
import mdi from './assets/sprites/mdi.svg'
import local from './assets/sprites/local.svg'
import outline from './assets/sprites/outline.svg'
import solid from './assets/sprites/solid.svg'

const app = createApp(App)

// Configs
addIconPacks({ outline, solid, mdi, local })

// Configs
// Fancybox.defaults.Hash = false
// Fancybox.bind('[data-fancybox="gallery"]', {})
// Fancybox.bind('[data-fancybox]', {})

// Use
app.use(createPinia())
// app.use(router)
// app.use(Vue3TouchEvents)
// app.use(vClickOutside)

app.mount('#app')
