import http from '../http'

export default {
  upload(payload, params, signal) {
    const url = `/files`
    return http.post(url, payload, {
      signal,
      params,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  delete(payload) {
    const url = `/files`
    return http.delete(url, { payload })
  },

  requestMedia(url) {
    return http.get(url)
  }
}
