import dayjs from 'dayjs'
import 'dayjs/locale/ru'
dayjs.locale('ru')

// Форматируем дату и время
export function formatDateTimeToday(date) {
  let res = ''
  if (date) {
    const date1 = dayjs().format('YYYY-MM-DD')
    const resDate = dayjs(date1).diff(date, 'day', true)
    const floored = Math.floor(resDate) + 1

    if (floored === 0) {
      res = `сегодня в ${dayjs(date).format('HH:mm')}`
    } else if (floored === 1) {
      res = `вчера в ${dayjs(date).format('HH:mm')}`
    } else {
      res = dayjs(date).format('DD MMMM YYYY в HH:mm')
    }
  }
  return res || '-'
}

// Формируем полную ссылку на файл
export function getFileUrl(path) {
  if (!(typeof path === 'string' && path)) return
  return import.meta.env.VITE_API_URL + '/' + path
}

// Формируем полную ссылку на файл
export function reloadPage(hard = false) {
  window.location.reload(hard)
}

// Скачиваем файл
export function downloadFile(path, filename) {
  const link = document.createElement('a')

  link.setAttribute('href', `${import.meta.env.VITE_API_URL}/${path}`)
  link.setAttribute('target', '_blank')
  link.setAttribute('download', filename)
  link.style.display = 'none'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
