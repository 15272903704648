<script setup>
import { UiIcon } from '@aistim/vue3-ui/components'
// Внешние импорты включая Vue
// import { RouterView, useRoute } from 'vue-router'
import { ref } from 'vue'
import { versionsApi } from '@/api'
import { downloadFile } from '@/plugins/helpers'

// Data:imports
// const route = useRoute()

// Watch
// watch(route, (val) => {
//   document.title = val?.meta?.title ? 'Telecom | ' + val.meta.title : 'Telecom'
// })
const serverVersion = ref(null)

// Created
init()

// Methods
async function init() {
  localStorage.clear()
  const { data } = await versionsApi.getList({ pageSize: 99999 })
  serverVersion.value = data.versions.rows[0]
}
</script>

<template>
  <UiIcon icon="info-circle" color="grey" size="24" />
  <h3 class="title">Браузерная версия более недоступна, скачайте мобильное приложение</h3>

  <template v-if="serverVersion">
    <button class="btn md primary jc-c" @click="downloadFile(serverVersion?.path, `telecom-${serverVersion?.name}`)">
      <UiIcon icon="download" size="16" />
      Скачать для Android
      <small class="tag sm primary-light">{{ serverVersion?.name }}</small>
    </button>
  </template>
</template>
