import localforage from 'localforage'
import { cloneDeep } from 'lodash'
import { useApplicationsStore } from '@/stores'

// APPLICATION
export async function localUpdateApplication(applicationId, application = null) {
  const applicationsStore = useApplicationsStore()
  if (!applicationsStore?.itemGetter && !application) return null

  // Подготавливаем данные
  const categories = application ? application : applicationsStore.itemGetter
  const payload = {
    date: new Date(),
    obj: cloneDeep(categories)
  }

  // Сохраняем в инексДБ
  await localforage.setItem(`${applicationId}-application`, payload)

  // Обновляем дату в сторе
  // await applicationsStore.UPDATE_ITEM_SAVED_DATE(applicationId, payload.date)
  return payload.date
}

// FILES
export async function localUpdateFiles(applicationId, blockId, file) {
  try {
    let res = { [blockId]: file }
    const oldData = await localforage.getItem(`${applicationId}-files`)
    if (oldData) res = { ...oldData, ...res }
    await localforage.setItem(`${applicationId}-files`, res)
  } catch (error) {
    console.log('LF: ', error)
  }
}
export async function localRemoveFile(applicationId, blockId) {
  try {
    const oldData = await localforage.getItem(`${applicationId}-files`)
    if (oldData) {
      delete oldData[blockId]
      await localforage.setItem(`${applicationId}-files`, oldData)
    }
  } catch (error) {
    console.log('LF: ', error)
  }
}
export async function localRemoveFileSingle(applicationId, blockId, fileId) {
  try {
    const oldData = await localforage.getItem(`${applicationId}-files`)
    if (oldData) {
      const index = oldData[blockId].findIndex((el) => el.id === fileId)
      if (index !== -1) oldData[blockId].splice(index, 1)
      await localforage.setItem(`${applicationId}-files`, oldData)
    }
  } catch (error) {
    console.log('LF: ', error)
  }
}

// Проверяем есть ли что сохранить
export async function localGetAll(applicationId) {
  const files = await localforage.getItem(`${applicationId}-files`)
  const application = await localforage.getItem(`${applicationId}-application`)
  return {
    files,
    application
  }
}

// Очищаем индексДБ
export function clearLocal() {
  return localforage.clear()
}
