import http from '../http'

export default {
  create(payload) {
    const url = `/notifications`
    return http.post(url, payload)
  },

  update(id, payload) {
    const url = `/notifications/${id}`
    return http.put(url, payload)
  },

  viewSwitchMultiple(payload) {
    const url = '/notifications/view-switch/multiple'
    return http.put(url, payload)
  },

  viewSwitchAll(payload) {
    const url = '/notifications/view-switch/all'
    return http.put(url, payload)
  },

  remove(id) {
    const url = `/notifications/${id}`
    return http.delete(url)
  },

  get(params) {
    const url = `/notifications`
    return http.get(url, { params })
  },

  getCount(params) {
    const url = `/notifications/count`
    return http.get(url, { params })
  }
}
