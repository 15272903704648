import http from '../http'

export default {
  getItems(params) {
    const url = `/applications`
    return http.get(url, params)
  },

  getItem(id, params) {
    const url = `/applications/${id}`
    return http.get(url, params)
  },

  gotoWork(id) {
    const url = `/applications/${id}/to-work`
    return http.patch(url)
  },

  updateItem(id, payload) {
    const url = `/applications/${id}`
    return http.put(url, payload)
  }
}
