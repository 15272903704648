import http from '../http'

export default {
  signIn(payload) {
    const url = `/auth/sign-in`
    return http.post(url, payload)
  },

  getProfile(params) {
    const url = `/me`
    return http.get(url, params)
  },

  updateProfile(payload) {
    const url = `/me`
    return http.put(url, payload)
  },

  forgotPassword(payload) {
    const url = `/auth/forgot-password`
    return http.post(url, payload)
  }
}
